<template>
    <div class="cs-block cs-orbital-slider-block" :class="orbitalClasses">
        <div class="cs-block-base">
            <div class="container">
                <h2 class="cs-title" v-if="data.title">
                    <ColorSplit :data="data.title" />
                </h2> 
                <h3 class="cs-sub-title" v-if="data.subTitle">{{data.subTitle}}</h3>
                <span class="cs-text" v-if="data.text" v-html="data.text"></span>
                <div :class="refName+'-holder'" class="slider-holder" style="opacity: 0;">                        
                    <Slider :data="data.list" v-slot="item">
                        <div :class="refName" class="slide-card">
                            <CSButton :data="item.slide.buttonLink">
                                <div class="image-container">
                                    <img class="down-side" :src="item.slide.image">
                                </div>
                                <div class="up-side" :style="`background:${item.slide.color}`">
                                    <div class="number" :style="`background:${item.slide.color}80`">
                                        <span>{{item.slide.number}}</span>
                                    </div>
                                    <div class="text-content" :style="`background:${item.slide.color}80`">
                                        <div class="title">{{item.slide.title}}</div>
                                        <div class="cs-text text" v-html="item.slide.text"></div>
                                    </div>
                                </div>
                            </CSButton>
                        </div>
                    </Slider>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {computed} from '../libs/common-fn';
    import $ from "jquery";
    export default {
        name: 'OribitalSlider',
        components: { 
            Slider: () => import("../blocks_components/Slider.vue"),
            ColorSplit: () => import("../components/CSUtilsColorSplit.vue"),
            CSButton: () => import("../components/CSButton.vue")
        },
        props: {
            data: {
                type: Object,
                default: () => {}
            }
        },
        data() {
            return {
                refName:'os-'+parseInt(Math.random()*99999),
                visible:false
            }
        },
        computed: {
            ...computed('OrbitalSlider'),
            orbitalClasses() {
                let result = {}
    
                result[`cs-style-${this.data.style}`] = true;

                if (result['cs-style-dark']) {
                    result['cs-style-dark'] = false;
                    result['cs-style-light'] = true;
                    result['type-2'] = true;
                }

                if (this.data.style == 'color') result['has-background'] = true;

                return result;
            },
            height(){
                console.log(this.$refs)
            }
        },
        mounted(){
            if(this.data.style !='dark'){
                const adjustHeight = ()=>{
                    console.log('working')
                    var maxHeightTitle = Math.max.apply(null, $(`.${this.refName}.slide-card .text-content .title`).map(function (){
                        return $(this).height();
                    }).get());
                    var maxHeightText = Math.max.apply(null, $(`.${this.refName}.slide-card .text-content .text`).map(function (){
                        return $(this).height();
                    }).get());
                    var heightOfSphere = $(`.${this.refName}.slide-card .number`).height()
                    $(`.${this.refName}.slide-card`).height(heightOfSphere+maxHeightTitle+maxHeightText+65)
                    $(`.${this.refName}-holder`).css('opacity','1')
                }
                setTimeout(adjustHeight,200)
                if(typeof window != 'undefined'){
                    let timer
                    window.addEventListener('resize', ()=>{
                        if(timer) clearTimeout(timer)
                        timer = setTimeout(adjustHeight, 100)
                    })
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import '../styles/main.scss';

.cs-block.cs-orbital-slider-block{

    .up-side {
        width: 100%;
    }

    text-align:center;

    .cs-text {
        display: block;
        margin-bottom: 30px;
    } 

    .cs-sub-title{
        font-weight: 400;
        padding: 10px 0;
        font-size: 20px;
    }
    .slider-holder{
        transition:1s;
    }

    &.cs-style-color{
        @include cs-block-color;
        *{
            color:white;
        }
    }
    &.cs-style-color, &.cs-style-light:not(.type-2) {
        .slick-slide:nth-child(odd) {
            .slide-card ::v-deep{
                .image-container {
                    bottom: 0;
                }
            }
            .up-side{
                flex-direction: column-reverse !important;

                .number {
                    border-bottom-right-radius: 50%;
                    border-bottom-left-radius: 50%;
                }
                .text-content{
                    border-top-left-radius: 15px;
                    border-top-right-radius: 15px;
                }
            }
        }
        .slick-slide:nth-child(even) {
            .up-side {
                .number {
                    border-top-right-radius: 50%;
                    border-top-left-radius: 50%;
                }
                .text-content{
                    border-bottom-left-radius: 15px;
                    border-bottom-right-radius: 15px;
                }
            }
        }


        .slide-card{
            ::v-deep {
                .cs-button {
                    display: block;
                    position: relative;
                    text-decoration: none;
                    color: white;
                    height: 100%;
                    >* {
                        position: absolute;
                    }
                    
                    &:after {
                        content: "";
                        display: block;
                        padding-top: 150%;
                    }

                    .up-side{
                    
                        height: 100%;
                        display: flex;
                        flex-direction: column;
                        background: none !important;
                        justify-content: stretch;
                        .text-content {
                            flex: 1;
                            .title {
                                padding: 15px 15px 0px 15px;
                                font-size: 1.2rem;
                                font-weight: 600;
                            }
                            .text {
                                font-size: 1rem;
                                font-weight: 400;
                                padding:15px;
                            }
                        }
                        

                        .number{
                            width: 100%;
                            display: flex;
                            justify-content:center;
                            align-items:center;
                            span {
                                z-index: 3;
                                font-size: 3rem;
                                font-weight: bold;
                            }
                            &:after {
                                content: "";
                                display: block;
                                padding-top: 100%;
                            }
                        }
                    }

                    .image-container {
                        width: calc(100% - 20px);
                        margin: 10px;
                        z-index: 1;
                        &:after {
                            content: "";
                            display: block;
                            padding-top: 100%;
                        }
                        img.down-side {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                            border-radius: 50%;
                            position: absolute;
                        }

                        img {
                            margin: 0px !important;
                        }
                    }
                }
            }
        }
    }
    &.type-2{
        .slider-holder{
            opacity:1 !important;
        }
        .slick-slide:nth-child(1 of div.slick-active){
            .up-side:hover{
                opacity: 1;
            }
            .up-side{
                opacity:0;
            }
        }
        .slide-card {
            overflow: hidden;
            border-radius: 50%;

            .up-side:hover{
                opacity:0;
            }

            ::v-deep {
                .cs-button-container {
                    position: relative;

                    &:after {
                        content: "";
                        display: block;
                        padding-top: 100%;
                    }
                }

                .cs-button {
                    position: unset;

                    img{    
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        margin: 0px !important;
                    }

                    > *{
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        width: 200%;

                        .number{
                            display: none;
                        }
                        &.up-side{

                            display: flex;
                            justify-content: center;
                            align-items: center;
                            height: 100%;
                            padding: 15px;
                            text-align: center;

                                                    
                            .title {
                                color: white;
                                margin-bottom: 5px;
                                font-size: 1.2rem;
                                font-weight: 500;
                                padding-bottom: 5px;
                                border-bottom: 1px solid white;
                            }

                            .text {
                                color:white;
                                font-size: 1rem;
                                font-weight: 300;
                            }
                            .title, .text{
                                text-align: center;
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>
